import { format, parseISO } from 'date-fns';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Avatar } from '../../../../components/Avatar';
import { TimelineItem } from '../../../../@types/activeDebt/inscription';
import IconSelections from '../../../../helpers/dynamicIcons';
import styles from './styles.module.scss';
import Format from '../../../../helpers/format';
import Show from '../../../../components/Show';

type ItemProps = {
  data: TimelineItem;
  first?: boolean;
  last?: boolean;
};

export function Item(props: ItemProps) {
  const renderContent = () => {
    if (props.data.type === 'payment_request') {
      return (
        <>
          <Grid item xs={12} sm={4} md={4} className={styles.requester}>
            <p>{t('inscription.timeline.requestedBy')}</p>
            <Show if={Boolean(props.data.detail.requested_by)}>
              <div className={styles.individual}>
                <Avatar
                  name={props.data.detail.requested_by}
                  color={undefined}
                  profile_picture={undefined}
                />
                <p>{props.data.detail.requested_by}</p>
              </div>
            </Show>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <p>
              <Trans
                i18nKey='inscription.timeline.requestedAt'
                components={{ bold: <strong /> }}
                values={{ value: Format.removeTimeFromDateString(props.data.detail.requested_at) }}
              />
            </p>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <p>
              <Trans
                i18nKey='inscription.timeline.paymentRequest'
                components={{ bold: <strong /> }}
                values={{ value: props.data.detail.payley_type }}
              />
            </p>
          </Grid>
        </>
      );
    }
    if (props.data.type === 'paid_dam') {
      return (
        <>
          <Grid item xs={12} sm={4} md={4}>
            <p>
              <Trans
                i18nKey='inscription.timeline.dueDate'
                components={{ bold: <strong /> }}
                values={{ value: props.data.detail.due_date }}
              />
            </p>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <p>
              <Trans
                i18nKey='inscription.timeline.paidAt'
                components={{ bold: <strong /> }}
                values={{ value: props.data.detail.paid_at }}
              />
            </p>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <p>
              <Trans
                i18nKey='inscription.timeline.type'
                components={{ bold: <strong /> }}
                values={{
                  value: props.data.detail.payment_request_code,
                  type: props.data.detail.payment_request_type,
                }}
              />
            </p>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <p>
              <Trans
                i18nKey='inscription.timeline.installment'
                components={{ bold: <strong /> }}
                values={{ value: props.data.detail.installments_number_to_sentence_with_ranges }}
              />
            </p>
          </Grid>
        </>
      );
    }
    if (props.data.type === 'received_compensation') {
      return (
        <>
          <Grid item xs={12} sm={4} md={4}>
            <p>
              <Trans
                i18nKey='inscription.timeline.cdaOrigin'
                components={{ bold: <strong /> }}
                values={{ value: props.data.detail.cda_origin }}
              />
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <p>
              <Trans
                i18nKey='inscription.timeline.compensationValue'
                components={{ bold: <strong /> }}
                values={{ value: props.data.detail.compensation_value }}
              />
            </p>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={12} sm={4} md={4} className={styles.requester}>
          <p>{t('inscription.timeline.createdBy')}</p>
          <Show if={Boolean(props.data.detail.created_by)}>
            <div className={styles.individual}>
              <Avatar
                name={props.data.detail.created_by}
                color={undefined}
                profile_picture={undefined}
              />
              <p>{props.data.detail.created_by}</p>
            </div>
          </Show>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <p>
            <Trans
              i18nKey='inscription.timeline.dueDate'
              components={{ bold: <strong /> }}
              values={{ value: props.data.detail.due_date }}
            />
          </p>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <p>
            <Trans
              i18nKey='inscription.timeline.type'
              components={{ bold: <strong /> }}
              values={{
                value: props.data.detail.payment_request_code,
                type: props.data.detail.payment_request_type,
              }}
            />
          </p>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <p>
            <Trans
              i18nKey='inscription.timeline.installment'
              components={{ bold: <strong /> }}
              values={{ value: props.data.detail.installments_number_to_sentence_with_ranges }}
            />
          </p>
        </Grid>
      </>
    );
  };
  return (
    <a
      className={`
        ${styles.container}
        ${props.first ? styles.first : ''}
        ${props.last ? styles.last : ''}
      `}
      href={props.data.detail.link_id_payment_request ? `/payment_request/${props.data.detail.link_id_payment_request}` : undefined}
      target='_blank'
    >
      <div className={styles.content}>
        <div className={`${styles.iconContent}`}>
          {IconSelections(props.data.type, props.data.detail.legal_action)}
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <p className={`${styles.time}`}>
            {format(parseISO(props.data.datetime), 'HH:mm')}
          </p>
          <p className={`${styles.productGenerated}`}>
            {props.data.detail.title}
          </p>
          <div className={styles.individualContent}>{renderContent()}</div>
        </Grid>
      </div>
    </a>
  );
}
